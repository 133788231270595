// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kemikaaliluettelo-js": () => import("./../../../src/pages/kemikaaliluettelo.js" /* webpackChunkName: "component---src-pages-kemikaaliluettelo-js" */),
  "component---src-pages-laskutus-js": () => import("./../../../src/pages/laskutus.js" /* webpackChunkName: "component---src-pages-laskutus-js" */),
  "component---src-pages-palvelut-js": () => import("./../../../src/pages/palvelut.js" /* webpackChunkName: "component---src-pages-palvelut-js" */),
  "component---src-pages-palvelut-maanrakentaminen-js": () => import("./../../../src/pages/palvelut/maanrakentaminen.js" /* webpackChunkName: "component---src-pages-palvelut-maanrakentaminen-js" */),
  "component---src-pages-palvelut-saneeraus-js": () => import("./../../../src/pages/palvelut/saneeraus.js" /* webpackChunkName: "component---src-pages-palvelut-saneeraus-js" */),
  "component---src-pages-palvelut-uudisrakentaminen-js": () => import("./../../../src/pages/palvelut/uudisrakentaminen.js" /* webpackChunkName: "component---src-pages-palvelut-uudisrakentaminen-js" */),
  "component---src-pages-referenssit-js": () => import("./../../../src/pages/referenssit.js" /* webpackChunkName: "component---src-pages-referenssit-js" */),
  "component---src-pages-vuokraamo-js": () => import("./../../../src/pages/vuokraamo.js" /* webpackChunkName: "component---src-pages-vuokraamo-js" */)
}

